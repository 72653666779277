import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get() {
    let result = await axios.get(`${API_ROUTES.clients.get}`);

    return result;
  },

  async save(data) {
    let result = await axios.post(`${API_ROUTES.clients.save}`, data);

    return result;
  },

  async delete(id) {
    let result = await axios.delete(`${API_ROUTES.clients.delete}/${id}`);

    return result;
  },

  async update(id, data) {
    let result = await axios.patch(`${API_ROUTES.clients.update}/${id}`, data);

    return result;
  },

  async getDocuments(clientId) {
    let result = await axios.get(
      `${API_ROUTES.clientDocuments.get}/${clientId}`
    );

    return result;
  },

  async uploadDocument(data) {
    let result = await axios.post(`${API_ROUTES.clientDocuments.save}`, data);

    return result;
  },

  async deleteDocument(id) {
    let result = await axios.delete(
      `${API_ROUTES.clientDocuments.delete}/${id}`
    );

    return result;
  }
};
